.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 24px;
    text-align: center;
    max-width: 420px;
    margin: auto;

    .icon {
        svg {
            font-size: 700%;
            margin-bottom: 16px;
            opacity: 0.3;
        }
    }
}
