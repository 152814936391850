@import './variables.module';

html,
body,
#root {
    min-height: 100vh;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Override React Material UI styles
.MuiTypography-h1 {
    font-size: 6rem * 0.6 !important;
}

.MuiTypography-h2 {
    font-size: 3.75rem * 0.6 !important;
}

.MuiTypography-h3 {
    font-size: 3rem * 0.6 !important;
}

.MuiTypography-h4 {
    font-size: 2.125rem * 0.78 !important;
}

// Override Push to Refresh styles
.ptr,
.ptr__children {
    min-height: 100vh;
}

// Override React Carousel styles
.carousel-root {
    height: 100%;

    .carousel.carousel-slider {
        height: 100%;

        .slider-wrapper {
            height: 100%;

            .slider {
                height: 100%;

                .slide {
                    height: 100%;
                }
            }
        }
    }
}

.provider-tile.carousel-root {
    height: 100%;

    .control-dots {
        text-align: right;
        padding-right: 8px;

        .dot {
            background-color: $first-color;
        }
    }
}

@keyframes first-input-pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba($first-color, 0.4);
        box-shadow: 0 0 0 0 rgba($first-color, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 20px 10px rgba($first-color, 0);
        box-shadow: 0 0 20px 10px rgba($first-color, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba($first-color, 0);
        box-shadow: 0 0 0 0 rgba($first-color, 0);
    }
}
