@import '../../variables.module';

.legalDocumentContent {
    font-size: 2.3rem;

    p {
        font-size: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        font-size: 2.125rem * 0.78 !important;
        font-weight: 500;
        color: $forth-color;
    }

    .footer {
        padding-top: 16px;
        font-size: 14px;
        color: $second-text-color;
    }
}
