.container {
    mask-repeat: no-repeat;
    mask-size: contain;

    @-webkit-keyframes LoaderAnimation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 51%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-moz-keyframes LoaderAnimation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 51%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-o-keyframes LoaderAnimation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 51%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes LoaderAnimation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 51%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .inside {
        width: 100%;
        height: 100%;

        // Color
        background: linear-gradient(272deg, #38b6ff, #ff66c4, #ffc834);
        background-size: 600% 600%;

        -webkit-animation: LoaderAnimation 1s ease infinite;
        -moz-animation: LoaderAnimation 1s ease infinite;
        -o-animation: LoaderAnimation 1s ease infinite;
        animation: LoaderAnimation 1s ease infinite;
    }
}
