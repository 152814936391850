@import '../../../variables.module';

.bottomNavigation {
    @media (max-width: 425px) {
        button {
            padding: 0 !important;
            min-width: 0 !important;
        }
    }
}
