.legalDocuments {
    a:link,
    a:visited {
        color: inherit;
    }
}

#recaptchaContainer {
    display: none;
}
