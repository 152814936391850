.bookingWidget {
    .bookingWidgetLine1,
    .bookingWidgetLine2,
    .bookingWidgetLine3 {
        padding-left: 16px;
        padding-right: 16px;
    }

    .bookingWidgetLine1 {
        display: flex;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;

        .bookingWidgetLineDesc {
            flex: 1;
        }

        .bookingWidgetLineButtons {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            padding-left: 16px;
            padding-top: 8px;
        }
    }

    .bookingWidgetLine3 {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 16px;
    }
}
