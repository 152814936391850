$first-color: #ff66c4;
$second-color: #38b6ff;
$third-color: #ffc834;
$forth-color: #7dd957;
$fifth-color: #d9dad9;
$sixth-color: #ff9e38;
$seventh-color: #8a60ff;

$first-text-color: #3f3f3c;
$second-text-color: #767676;

$white-color: #fffdfa;

:export {
    firstColor: $first-color;
    secondColor: $second-color;
    thirdColor: $third-color;
    forthColor: $forth-color;
    fifthColor: $fifth-color;
    sixthColor: $sixth-color;
    seventhColor: $seventh-color;

    firstTextColor: $first-text-color;
    whiteColor: $white-color;
}
