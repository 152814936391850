@import '../../../../variables.module.scss';

.morePage {
    display: flex;
    gap: 64px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
    }

    .buttonsGroup {
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-width: 320px;

        a {
            text-decoration: none;
        }
    }
}
