.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    line-height: 0px;
}

.titleLines {
    list-style: none;
    padding-left: 0;
    margin: 0;
    padding-top: 36px;
}
