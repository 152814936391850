@import '../../../../variables.module';

.box {
    width: 100%;
    position: relative;
    top: -24px;

    .subBox {
        display: flex;

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .cards {
        padding-top: 8px;
        width: 100%;
        max-width: 400px;
    }

    .transactions {
        margin-left: 48px;
        margin-top: 32px;
        flex: 1;

        .list {
            width: 100%;
        }

        .empty {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 24px;
            text-align: center;
            max-width: 420px;
            margin: auto;
        }
    }

    @media (max-width: 900px) {
        .transactions {
            margin-left: 0;
        }
    }
}
