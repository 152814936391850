@import '../../variables.module';

.line {
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.info {
    flex: 1;

    .service {
        &.loyalty {
            color: $second-color;
        }
        &.gift {
            color: $first-color;
        }
    }

    .provider {
        margin-top: 8px;
    }
}
