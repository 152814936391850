@import '../../../../variables.module.scss';

.bottomNavigation {
    @media (max-width: 425px) {
        button {
            padding: 0 !important;
            min-width: 0 !important;
        }
    }

    .selected {
        &.officeMainHome_Professionals {
            color: $seventh-color !important;
        }

        &.officeMainHome_Bookings {
            color: $second-color !important;
        }

        &.officeMainHome_Card {
            color: $third-color !important;
        }

        &.officeMainHome_More {
            color: $forth-color !important;
        }

        //

        &.officeMainHome_Academy {
            color: $second-color !important;
        }
    }
}
