@import '../../variables.module';

.paper {
    transition: 0.4s ease-out;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 85.6 / 53.98; /* or 1.586:1 */
    border-radius: 8px !important;
    z-index: 1;
    position: relative;
    overflow: hidden;

    &:not(:first-child) {
        margin-top: -50%;
    }

    .card {
        width: 100%;
        height: 100%;

        &.loyalty {
            background-color: $white-color;
        }

        &.gift {
            background: radial-gradient(
                    ellipse farthest-corner at right bottom,
                    #ff6b6b 0%,
                    #ff3b3b 8%,
                    #9f3333 30%,
                    #8a2f2f 40%,
                    transparent 80%
                ),
                radial-gradient(
                    ellipse farthest-corner at left top,
                    #ffd6d6 0%,
                    #ffc1c1 8%,
                    #d18f8f 25%,
                    #5d4a1f 62.5%,
                    #5d4a1f 100%
                );
        }

        &.founder {
            background: radial-gradient(
                    ellipse farthest-corner at right bottom,
                    #fedb37 0%,
                    #fdb931 8%,
                    #9f7928 30%,
                    #8a6e2f 40%,
                    transparent 80%
                ),
                radial-gradient(
                    ellipse farthest-corner at left top,
                    #ffffff 0%,
                    #ffffac 8%,
                    #d1b464 25%,
                    #5d4a1f 62.5%,
                    #5d4a1f 100%
                );
        }

        .front {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: 100%;

            .logoBox {
                width: 60%;
            }

            .cardContent {
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .cardName {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .clientName {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 10px;
                }

                .description {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 4px;
                }

                .cardName {
                    font-weight: 600;
                }

                .clientName,
                .description {
                    text-align: right;
                    font-weight: 500;
                }
            }
        }
    }
}
