@import '../../variables.module';

.userLine {
    margin-bottom: 8px;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px;

    .line1 {
        display: flex;
        gap: 8px;

        .text {
            flex: 1;
            padding-top: 5px;
        }
    }
}
