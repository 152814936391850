@import '../../../../variables.module';

.line0 {
    .bookingWidget {
        // min-height: 160px;

        .bookingWidgetLine1,
        .bookingWidgetLine2,
        .bookingWidgetLine3 {
            padding-left: 16px;
            padding-right: 16px;
        }

        .bookingWidgetLine1 {
            display: flex;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;

            .bookingWidgetLineDesc {
                flex: 1;
            }

            .bookingWidgetLineButtons {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                padding-left: 16px;
                padding-top: 8px;
            }
        }

        .bookingWidgetLine3 {
            display: flex;
            align-items: center;
            padding-top: 16px;
            padding-bottom: 8px;
        }
    }
}

.line1 {
    width: 100%;
    display: flex;
    gap: 32px;
    margin-top: 24px;

    @media (max-width: 690px) {
        flex-direction: column;
    }

    .widget1,
    .widget2_3 {
        flex: 1;
    }

    .widget2_3 {
        display: flex;
        gap: 32px;

        @media (max-width: 690px) {
            gap: 16px;
        }

        .widget2,
        .widget3 {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .widget2Paper,
        .widget3Paper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    .widget3 {
        .widget3Paper {
            background: radial-gradient(
                    ellipse farthest-corner at right bottom,
                    #ff6b6b 0%,
                    #ff3b3b 8%,
                    #9f3333 30%,
                    #8a2f2f 40%,
                    transparent 80%
                ),
                radial-gradient(
                    ellipse farthest-corner at left top,
                    #ffd6d6 0%,
                    #ffc1c1 8%,
                    #d18f8f 25%,
                    #5d4a1f 62.5%,
                    #5d4a1f 100%
                );
        }
    }
}

.line2 {
    width: 100%;
    margin-top: 32px;
}

.line3 {
    width: 100%;
    margin-top: 32px;
}

.professionalActions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;

    & > div {
        width: 140px;
    }

    @media (max-width: 485px) {
        & > div {
            width: calc(50% - 8px);
        }
    }

    @media (max-width: 320px) {
        & > div {
            width: 100%;
        }
    }
}
