@import '../../../variables.module';

.notification {
    margin-bottom: 8px;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px;

    .line1 {
        display: flex;
        gap: 8px;

        .read {
            margin-top: 16px;
        }

        .text {
            flex: 1;
        }

        .image {
            max-width: 64px;

            img {
                width: 100%;
                border-radius: 8px;
            }
        }
    }

    .imageMega {
        margin-top: 8px;

        img {
            width: 100%;
            border-radius: 8px;
        }
    }
}
